<template>
  <vx-card id="video-banner-create-page">
    <form>
      <!-- Title -->
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.title') }}</strong></legend>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_tm" placeholder="Turkmen" v-model="banner.title_tm" />
            <span class="text-danger text-sm" v-show="errors.has('title_tm')">{{ errors.first('title_tm') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_ru" placeholder="Russian" v-model="banner.title_ru" />
            <span class="text-danger text-sm" v-show="errors.has('title_ru')">{{ errors.first('title_ru') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_en" placeholder="English" v-model="banner.title_en" />
            <span class="text-danger text-sm" v-show="errors.has('title_en')">{{ errors.first('title_en') }}</span>
          </div>
        </fieldset>
      </div>

      <!-- Description -->
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pt-2 pb-4" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.description') }}</strong></legend>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_tm" placeholder="Turkmen" v-model="banner.description_tm" />
            <span class="text-danger text-sm" v-show="errors.has('description_tm')">{{ errors.first('description_tm') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_ru" placeholder="Russian" v-model="banner.description_ru" />
            <span class="text-danger text-sm" v-show="errors.has('description_ru')">{{ errors.first('description_ru') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_en" placeholder="English" v-model="banner.description_en" />
            <span class="text-danger text-sm" v-show="errors.has('description_en')">{{ errors.first('description_en') }}</span>
          </div>
        </fieldset>
      </div>

      <!-- Link -->
      <div class="mb-4">
        <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
          <legend class="px-2"><strong>{{ $t('fields.attachTo') }}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="products">{{ $t('products') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="collection">{{ $t('comboProducts') }}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('child_type')">{{ errors.first('child_type') }}</span>
          <div v-if="child_type && child_type !== 'url'" class="vx-row" :key="child_type">
            <div class="vx-col w-full mb-4 mx-2">
              <template v-if="child_type !== 'products'">
                <v-select v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('select')" v-model="banner.collectionId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
              </template>
              <template v-else>
                <v-select v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('select')" multiple v-model="banner.productIds" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
              </template>
              <span class="text-danger text-sm" v-show="errors.has('childId')">{{ errors.first('childId') }}</span>
            </div>
          </div>
        </fieldset>
      </div>

      <!-- Typa addvertisement -->
      <div class="mb-4">
        <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
          <legend class="px-2"><strong>{{ $t('fields.type') }}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="vx-col my-5 mr-4 flex">
              <vs-switch v-model="banner.isAdd" />
              <label class="ml-3">{{ $t('fields.isAdd') }}?</label>
            </li>
          </ul>
          <div v-if="banner.isAdd" class="vx-row">
            <div class="vx-col w-full mb-4 mx-2">
              <v-select v-validate="'required'" :options="brands" name="bannerId" :placeholder="`${$t('selectOne')} ${$t('brand')}`" v-model="banner.brandId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
              <span class="text-danger text-sm" v-show="errors.has('bannerId')">{{ errors.first('bannerId') }}</span>
            </div>
          </div>
        </fieldset>
      </div>

      <!-- Upload buttons -->
      <div class="vx-row">

        <div v-if="!banner.photo" class="vx-col upload-img mt-3">
          <input v-validate="'required'" name="preview_image" type="file" class="hidden" ref="uploadPrevInput" @change="updateCurrPrev" accept="image/*">
          <vs-button @click="$refs.uploadPrevInput.click()" type="border" icon-pack="feather" icon="icon-upload">{{`${$t('upload')} ${$t('props.preview')}`}}</vs-button>
          <span class="text-danger text-sm" v-show="errors.has('preview_image')">{{ errors.first('preview_image') }}</span>
        </div>

      </div>

      <div class="vx-row mt-4">
        <div class="vx-col w-1/2" v-if="banner.photo">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="banner.photo" alt="img" class="responsive">
          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="uploadPrevInput" @change="updateCurrPrev" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.uploadPrevInput.click()">{{ $t('update') }}</vs-button>
            <vs-button type="flat" color="#999" @click="banner.photo = null">{{ $t('remove') }}</vs-button>
          </div>
        </div>

      </div>

      <div class="vx-row px-4">
        <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">{{ $t('submit') }}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="cancelForm" class="ml-4 mt-5 block">{{ $t('cancel') }}</vs-button>
      </div>
      <!-- <vs-upload text="Slider image" action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" /> -->
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      banner: {
        title_tm: '',
        title_ru: '',
        title_en: '',
        description_tm: '',
        description_ru: '',
        description_en: '',
        collectionId: null,
        brandId: null,
        productIds: [],
        isAdd: false,
        photo: null
      },
      child_type: null,
      products: [],
      collections: [],
      brands: []
    }
  },
  watch: {
    async child_type (newVal, oldVal) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newVal !== oldVal) {
        switch (newVal) {
        case 'products':
          this.banner.collectionId = null
          // await this.$http.get('/products', { params: { isActive: true } }).then(response => { this.linkOptions = response.data.data })
          break
        case 'collection':
          this.banner.productIds = []
          // await this.$http.get('/collections', { params: { isActive: true } }).then(response => { this.linkOptions = response.data.data })
          break
        }
      }
    }
  },
  computed: {
    linkOptions () {
      return this.child_type === 'products' ? this.products : this.collections
    }
  },
  methods: {
    updateCurrPrev (input) {
      // console.log(((input.target.files[0].size / 1024) / 1024).toFixed(4))
      if (input.target.files && input.target.files[0]) {
        if (((input.target.files[0].size / 1024) / 1024) > 0.5) {
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: this.$t('file_size_max'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        } else {
          const reader = new FileReader()
          reader.onload = e => {
            this.banner.photo = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      }
    },
    async fetchBrands () {
      await this.$http.get('/brands', { params: { isActive: true } }).then(response => { this.brands = response.data.data })
      this.$http.get('/products', { params: { isActive: true } }).then(response => { this.products = response.data.data })
      this.$http.get('/collections', { params: { isActive: true } }).then(response => { this.collections = response.data.data })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/videobanners/add', { ...this.banner }).then(response => {
            if (response.status === 201) {
              this.$vs.notify({
                title: this.$t('notify.success'),
                text: `${this.$t('banner')} ${this.$t('notify.sCreated')}`,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.push('/video-banners')
            }
          }).catch(err => {
            this.$vs.notify({
              title: this.$t('notify.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: this.$t('notify.formInvalid'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      })
    },
    cancelForm () { this.$router.push('/video-banners') },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  },
  created () { this.fetchBrands() }
}
</script>

<style lang="scss" scoped>
.theme-dark {
  fieldset {
    border-color: #435473 !important;
  }
}
</style>
